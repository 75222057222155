<template>
  <div class="ApprovalTag">
    <!-- 待审批指标 -->
    <van-nav-bar :title="$t('ApprovalTaget.apptxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-tabs v-model="activeName"
                color="#2B8DF0"
                title-active-color="#2B8DF0"
                @change="changeAct">
        <van-tab v-if="$t('ApprovalTaget.apptxt2')"
                 :title="$t('ApprovalTaget.apptxt2')"
                 name="360Tag"></van-tab>
        <van-tab v-if="$t('ApprovalTaget.apptxt3')"
                 :title="$t('ApprovalTaget.apptxt3')"
                 name="KPITag"></van-tab>
      </van-tabs>
      <van-search v-if="activeName == 'KPITag'"
                  v-model="searchVal"
                  shape="round"
                  :placeholder="'搜索'"
                  @search="onSearch" />
      <van-pull-refresh :class="activeName == 'KPITag'?'KPITagRefresh':''"
                        v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item,index) in assessList"
               :key="index">
            <van-cell v-if="activeName == '360Tag'"
                      class="ass_cell"
                      size="large"
                      :title="item.khtimename"
                      :value="item.empname"
                      :label="item.khname"
                      is-link
                      @click="goToApply(item)" />
            <van-cell v-else
                      class="ass_cell"
                      size="large"
                      :title="item.khtimename"
                      :value="item.empname"
                      :label="item.planname"
                      is-link
                      @click="goToApply(item)" />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getMy360ForCheckList, getMyKpiForCheckList } from '@api/wxjx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      activeName: '360Tag',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      searchVal: ""
    }
  },
  watch: {
    searchVal (n) {
      this.onSearch()
    }
  },
  created () { },
  beforeRouteEnter (to, from, next) {
    let is360 = true
    if (from.name == 'jxApprovalTagApply')
    {
      is360 = from.query.is360
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (is360 == true || is360 == 'true')
      {
        vm.activeName = '360Tag'
      } else
      {
        vm.activeName = 'KPITag'
      }
    })
  },
  methods: {
    onSearch () {
      if (this.searchVal)
      {
        this.assessList = this.assessList.filter(
          item => item.khtimename.indexOf(this.searchVal) > -1 || item.planname.indexOf(this.searchVal) > -1 || item.empname.indexOf(this.searchVal) > -1
        )
      } else
      {
        this.getMyKpiList()
      }
    },
    onClickLeft () {
      this.$router.push('/home')
    },
    /// 9.1.获取待审批的360考核方案列表
    getMy360List () {
      let _this = this
      getMy360ForCheckList({ empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true
          // 无数据
        } else
        {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 9.1.获取待审批的360考核方案列表
    getMyKpiList () {
      let _this = this
      getMyKpiForCheckList({ empid: this.userInfo.empid }).then(res => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true
          // 无数据
        } else
        {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true

        }
      })
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = []
        this.isLoadingAwait = false
      }
      if (this.activeName == '360Tag')
      {
        this.getMy360List()
      } else
      {
        this.getMyKpiList()
      }
    },
    onRefreshAwait () {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct (name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goToApply (item) {
      let is360 = true
      const mautoid = item.autoid
      if (this.activeName == '360Tag')
      {
        is360 = true
      } else
      {
        is360 = false
      }
      this.$router.push({
        path: '/jxApprovalTagApply',
        query: { title: item.khtimename, is360: is360, mautoid: mautoid, lastscore: item.lastscore, is_jjp: item.is_jjp ? item.is_jjp : '', is_zjp: item.is_zjp ? item.is_zjp : '' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    .zb_group {
      // .van-cell::after {
      //   position: absolute;
      //   box-sizing: border-box;
      //   content: " ";
      //   pointer-events: none;
      //   right: 0.42667rem;
      //   bottom: 0;
      //   left: 0.42667rem;
      //   border-bottom: 2px solid #ebedf0;
      //   -webkit-transform: scaleY(0.5);
      //   transform: scaleY(0.5);
      // }
      .ass_cell {
        margin: 10px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
      }
    }
  }
}
</style>